h1,h2,h3 {
  font-family: "Times New Roman"; }
body {
  font-family: Arial !important; }
.faq-detail {
  font-family: Arial !important; }
.top-line {
  background-color: #000000;
  padding: 10px;
  font-weight: bold;
  color: white;
  span {
    color: white;
    padding-left: 20px; } }
.navbar-brand {
  img {
    width: 100%;
    height: 150px; } }
.erb {
  height: 50px;
  margin-top: 110px;
  margin-right: 25px; }
.top-image {}

.navbar-default {
  background-image: none;
  background-color: #005495;
  margin-bottom: 0;
  border-color: transparent;
  border-radius: 0px;
  box-shadow: none; }
.navbar-default .navbar-nav>li>a, .navbar-default .navbar-text {
  color: white; }
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.open>a {
  color: white; }
#lightSlider {
  padding-left: 0;
  list-style-type: none; }
.right {}

.contact-content {
  padding: 15px; }
.middle {
  color: #c4112f;
  &:hover {
    color: #683c11; } }
.test {
  a {
    display: block; }
  img {
    height: 30px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px; } }
body {
  background-image: url("../images/podorys_bg.svg");
  background-repeat: no-repeat;
  background-color: rgba(246,245,243,0.8);
  background-size: 30%;
  background-position-x: right;
  background-attachment: fixed; }
.container {
  background-color: white;
  padding: 0; }
.gall {
  margin-left: 0px;
  margin-right: 0px; }
.forest {
  background-image: url("../images/hrad_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  h1 {
    text-align: center;
    color: white;
    font-size: 60px;
    margin-top: 30px; }
  p {
    text-align: center;
    color: white;
    margin-bottom: 30px; } }
.hold {
  height: 150px; }
.more {
  border: 1px solid white;
  color: white;
  border-radius: 40px;
  padding: 10px;
  &:hover {
    background-color: white;
    color: #683c11;
    text-decoration: none; } }
.special {
  border: 1px solid white;
  color: white;
  border-radius: 40px;
  padding: 10px;
  &:hover {
    background-color: white;
    color: #683c11;
    text-decoration: none; } }
.maps {
  background-color: #f7f7f5;
  iframe {
    width: 100%; } }
.author {
  color:#683c11 {}
  padding: 10px; }
.art-img {}

.art-nadpis {
  color: #866341;
  font-weight: bold;
  margin-left: 25px; }
.date {
  color: #cdc6b6; }
.art-podnadpis {
  color: #9a7d60;
  margin-top: 5px; }

hr {
  border: 1px solid #683c11; }
.read {
  padding: 5px;
  background-color: #683c11;
  border-radius: 35px;
  color: white;
  font-size: 18px;
  width: 80px;
  display: block;
  text-align: center;
  float: right;
  position: relative;
  top: 20px;
  &:hover {
    color: white;
    background-color: #764516; } }
.bord {
  border: 2px solid #f7f4f2;
  min-height: 350px;
  padding: 15px;
  margin: 25px; }
.project {
  color: #683c11;
  font-size: 23px;
  margin-top: 15px;
  font-weight: bold;
  font-family: "Times New Roman"; }
.project-img {
  margin-top: 40px;
  width: 90%; }
.padding {
  padding-left: 0px; }
.menu_level_1 {
  list-style-type: none;
  padding-left: 0;
  text-align: left;
  display: none;
  li {
    &:hover {
      background-color: #004479; }
    a {
      color: white;
      padding: 10px;
      display: block;
      padding-left: 15px;
      &:hover {
        text-decoration: none; } } } }
.navbar-nav>li {
  &:hover {
    background-color: #004479;

    a {
      &:hover {
        color: white !important; } } } }
.navbar-nav li:hover .menu_level_1 {
  display: block; }
.menu_level_1 {
  display: none;
  position: absolute;
  background-color: #005495;
  /*box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2)*/
  min-width: 12em;
  z-index: 9999;
  color: #777;
  @include transition();
  padding-left: 0em; }
.menu_level_1 a {
  color: {} }
#lang {
  padding-top: 20px;
  padding-left: 140px;
  li:first-child {
 } }   // display: none
#lang li a {
  border: 2px solid black;
  border-radius: 15px;
  color: black; }
/*.lang
  background-image: url("../images/icon_fb.svg")
  background-repeat: no-repeat
  background-position: 3px 20px
  background-size: 30px*/
.code {
  li {
    a {
      &:hover {
        text-decoration: none; } } } }
.nav>li>a:focus {
  background-color: #004479 !important;
  background-image: none !important;
  color: white !important; }
.navbar-nav>.active>a {
  background-color: #004479 !important;
  background-image: none !important; }
.click {
  height: 30px;
  position: absolute;
  top: 20px;
  left: 110px; }
.castle {
  width: 150px;
  height: 150px; }
.castle-2 {
  width: 100%;
  height: auto; }
.gal {
  width: 100%;
  height: 150px;
  margin-bottom: 30px;
  margin-top: 30px;
  a {
    display: inline-block;
    float: left;
    color: #9a7d60;
    font-size: 21px;
    &:hover {
      color: #683c11; } } }
.gal-img {
  float: left;
  margin-left: 15px;
  p {
    color: #9a7d60;
    font-size: 22px; } }
.gal-detail {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center; }
form.contact_form.ng-pristine.ng-valid.ng-scope {
  padding: 15px; }
.btn {
  padding: 5px;
  background-color: #683c11;
  border-radius: 40px;
  color: #fff;
  font-size: 18px;
  width: 110px;
  display: block;
  text-align: center;
  &:hover {
    color: white;
    background-color: #764516; } }
.form-control {
  width: 95%; }
.gal-name {
  margin: 30px;
  font-size: 22px;
  color: #9a7d60; }
.menu-content-edit {
  padding: 15px; }
.selected {
  display: none; }

@media (max-width: 1200px) {
  .erb {
    margin-top: 90px; }
  .click {
    left: 80px; }
  #lang {
    padding-left: 110px; } }
@media (max-width: 991px) {
  .erb {
    margin-top: 60px; }
  .project {
    margin-left: 35px; }
  .project-img {
    width: 60%;
    margin-left: 35px;
    margin-bottom: 40px; }
  .click {
    left: 30px; }
  #lang {
    padding-left: 60px; }
  .navbar-brand img {
    height: 110px; } }
@media (max-width: 767px) {
  .more {
    width: 102px;
    margin: 15px auto;
    display: block; }
  .special {
    width: 112px;
    margin: 15px auto;
    display: block; }
  .navbar-brand {
    float: none;
    display: block;
    img {
      height: 90px;
      margin: 0 auto; } }
  .right {
    display: none; }
  .aaa {
    height: 110px; }
  .erb {
    margin-top: 30px; }
  .test a {
    display: none; }
  .navbar-default .navbar-toggle {
    border-color: transparent; }
  .navbar-default .navbar-toggle .icon-bar {
    background-color: white; }
  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background-color: transparent; } }

@media (max-width: 570px) {
  .click {
    left: 0px; }
  #lang {
    padding-left: 20px; } }
@media (max-width: 471px) {
  .erb {
    margin-top: 45px;
    margin-right: 15px; }
  .click {
    top: 35px; }
  #lang {
    padding-top: 35px !important; } }

@media (max-width: 425px) {
  .click {
    position: relative;
    left: -52px;
    top: 101px; }
  ul#lang {
    padding-left: 0px;
    padding-top: 70px !important; }
  ul#lang li {
    margin-left: -10px; }
  .erb {
    margin-right: 0px;
    height: 49px;
    display: none; }
  .navbar-brand img {
    max-width: 180%;
    width: 100px; }
  .top-line span {
    padding-left: 5px; }
  .scroll {
    top: 41px; }
  .top-line {
    position: fixed;
    width: 100%;
    z-index: 19; }
  .navbar {
    position: fixed;
    width: 100%;
    z-index: 19; }
  .aaa {
    margin-top: 40px; } }
